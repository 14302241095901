/*carousel */
.banner-item.carousel-item {
  height: 100vh;
  position: relative;
}

/* home page banner */

.product-banner-item.carousel-item {
  height: 200px;
}

.testimonial .carousel-caption p {
  text-align: center;
}

.carousel-caption {
  bottom: 6.25rem;
  color: black;
  width: 100%;
  right: 0;
  left: 0;
}

.carousel-caption .row {
  justify-content: start;
  margin-left: 10rem;
  max-width: 800px;
}

.carousel-caption h2,
.carousel-caption h1,
.carousel-caption p,
.hero-btn,
.social-top {
  text-align: start;
}

.slider-text-info span {
  color: rgb(189, 50, 40);
}

@media (max-width: 767px) {
  .carousel-caption {
    top: 5rem;
    bottom: 1rem;
  }

  .resp-text h4 {
    font-size: 14px;
    padding: 0px !important;
  }

  .resp-head {
    padding: 3px;
  }

  .resp-product-wrap {
    margin-top: 0px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .resp-product-wrap .product-content {
    margin-top: 10px;
  }
  .resp-product-wrap .product-content h3 {
    line-height: 1.6;
  }

  .resp-product-action button {
    background-color: rgb(189, 50, 40);
    color: white;
    border: none;
  }
  .resp-product-action {
    opacity: 1 !important;
    top: 84% !important;
  }
  .resp-product-wrap .product-image > a {
    height: 251px;
  }
  .respcat {
    height: auto !important;
  }
}

.carousel-item img {
  object-fit: cover;
  height: 100%;
}

.testimonial.carousel.slide {
  /* background-image: url("../public/assets/images/bg/testimonial-bg.webp");
  background-size: cover;
  background-repeat: no-repeat; */
  background: linear-gradient(90deg, #5170ff, #ff66c4);
}

.testimonial-carousel .carousel-item {
  height: 535px;
}

/* ===================== */
/* gradients on homepage */

/* .carousel-control-next:hover {
  background: transparent;
  background: linear-gradient(
    90deg,
    rgba(249, 249, 249, 0) 0%,
    rgba(255, 88, 88, 0.513) 100%
  );
}
.carousel-control-prev:hover {
  background: transparent;
  background: linear-gradient(
    90deg,
    rgba(255, 88, 88, 0.513) 0%,
    rgba(249, 249, 249, 0) 100%
  );
} */
.product-action button {
  background-color: rgb(189, 50, 40);
  color: white;
}

.carousel-control-prev i,
.carousel-control-next i {
  opacity: 0;
  margin-top: 50px;
  transition: margin-top 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.carousel:hover .carousel-control-prev i,
.carousel:hover .carousel-control-next i {
  opacity: 1;
  margin-top: 0px;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  bottom: 50%;
}

.carousel-control-prev i,
.carousel-control-next i {
  background-color: rgb(72 72 72 / 44%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* display: none; */
}

.carousel-control-prev i:hover,
.carousel-control-next i:hover {
  background-color: rgb(189, 50, 40);
}

.banner-content .banner-content-box h4,
.banner-content .banner-content-box button {
  color: white;
}

.single-banner-two {
  /* background: rgb(255, 172, 172);
  background: linear-gradient(
    164deg,
    rgba(255, 172, 172, 1) 0%,
    rgba(255, 255, 255, 0.15738795518207283) 18%,
    rgba(255, 248, 248, 1) 82%,
    rgba(255, 172, 172, 1) 100%
  ); */
  background-image: url(../public/assets/images/banner/bottom_banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.single-banner-two h3,
.single-banner-two button {
  color: white;
}

.single-banner-two button {
  background-color: rgb(189, 50, 40);
  border: none;
}

.single-banner-two .banner-content-box span {
  color: black;
}

.porduct-area.section-pt.section-pb-60 {
  background-color: rgba(211, 211, 211, 0.16);
}

.login-main .row {
  border-radius: 20px;
  background: linear-gradient(90deg, #5170ff, #ff66c4);
}

/* ======================== */

/* product page */

.freedelivery {
  /* delivery icon on product page */
  border: 2px solid green;
}

.portfolio-small-image {
  border: 1px solid lightgray;
}

.product-small-images {
  max-height: 500px;
  overflow-y: auto;
  cursor: pointer;
}

.product-small-images::-webkit-scrollbar {
  display: none;
}

/* ================================ */
/* range slider */
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: rgb(189, 50, 40) !important;
  box-shadow: 0 0 0 5px rgb(189, 50, 40) !important;
}

.rc-slider-rail {
  background-color: black !important;
}

.rc-slider-track {
  background-color: red !important;
}

.rc-slider-handle {
  opacity: 1 !important;
  border: solid 2px rgb(189, 50, 40) !important;
}

/* header */
.fl-header {
  transition: 0.3s;
}

.fl-header.is-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  box-shadow: rgb(47, 38, 77) 0px 20px 30px -10px;
}

a.loginbtn {
  /* padding: 0; */
  color: white;
  font-size: 15px;
}

.loginbtn {
  background-color: rgb(189, 50, 40);
  border: none;
  padding: 8px 30px;
}

/* cart page */
.coupon-animation-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.356);
}

.coupon-animation {
  padding: 20px;
  /* border-radius: 20px; */
  color: white;
}

.coupon-animation h3 {
  color: white;
}

.plantmore-product-quantity b {
  padding: 5px 20px;
}

.plantmore-product-quantity button {
  padding: 0px 5px;
}

.plantmore-product-thumbnail img {
  max-height: 100%;
  object-fit: contain;
  max-width: 100%;
  height: 100px;
}

/* checkout */
.appliedcoupon {
  border: 2px dotted green;
  background-color: rgba(182, 226, 182, 0.425);
  padding: 5px 5px;
}

.appliedcoupon i {
  cursor: pointer;
}

.cart-items {
  min-height: 180px;
  max-height: 400px;
  overflow-y: auto;
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 50, 40);
  /* Color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #1e0000;
  /* Color of the scrollbar thumb on hover */
}

.products-list {
  position: fixed;
  top: 83px;
  left: 1vw;
  right: 1vw;
  background-color: white;
  max-width: 100vw;
  height: 500px;
  /* overflow-y: auto; */
  /* border: 10px solid rgba(0, 0, 0, 0); */
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
  /* transition: 0.5s ease-in-out; */
  z-index: 999;
}

.products-list-left {
  position: relative;
  display: flex;
  align-items: center;
}

.products-list-left h3 {
  position: absolute;
  bottom: 15%;
  text-align: center;
  width: 100%;
  /* left: 50%; */
  /* right: 50%; */
}

.products-list-left img {
  margin: auto;
  max-height: 100%;
  width: 100%;
}

.products-list-row {
  overflow-y: auto;
  max-height: 400px;
}

.cat-items > a {
  padding: 10px 0 !important;
  height: 70px !important;
}

.cat-items > a > img {
  object-fit: contain;
  height: 100%;
}

.fa-sort-down {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.cart-table tbody {
  max-height: 500px;
  overflow-y: auto;
}

/* .filter-price-cont .input-type input{
  width: 100px;
} */
.forgot-pass.container-fluid {
  /* height: calc(100vh - 83px); */
  background: linear-gradient(90deg, #5170ff, #ff66c4);
}

.loginmain2 button {
  color: white;
}

.loginform i {
  color: rgb(189, 50, 40);
}

.mini-cart > li > a {
  font-size: medium;
}

.shopping-cart-wrap.profile ul.mini-cart {
  width: 150px;
}

.header-bottom-area.transparent-header .main-navigation a.main-menu-list-items {
  color: white;
}

a.main-menu-list-items:hover {
  color: rgb(189, 50, 40);
}

.fl-header.is-sticky .main-navigation a.main-menu-list-items {
  color: rgb(0, 0, 0);
}

.fl-header.is-sticky a.main-menu-list-items:hover {
  color: rgb(189, 50, 40);
}

.header-bottom-area.transparent-header .intoggle-icons {
  color: white;
}

.fl-header.is-sticky .right-blok-box i {
  color: black;
}

/* linear gradient (90deg, #5170ff , #ff66c4 )*/
.corporate-gifts-wrap {
  position: absolute;
  bottom: 10%;
  /* z-index: 99; */
  left: 6%;
  /* transform: translate(0%, -50%) rotate(270deg); */
  /* transform-origin: 100% 100%; */
}

.corporate-gifts-wrap-mobile {
  position: absolute;
  top: 34%;
  /* z-index: 99; */
  left: 12%;
  /* transform: translate(0%, -50%) rotate(270deg); */
  /* transform-origin: 100% 100%; */
}

.corporate-gifts a {
  font-size: 15px;
}

.corporate-gifts {
  padding: 10px;
  border: none;
  color: white;
  background-color: rgb(189, 50, 40);
}

/* ========================================================= */
.allcat.single-banner {
  overflow: hidden;
  height: 266px;
  /* width: 266px; */
}

.home.single-banner {
  overflow: hidden;
  /* height: 352px; */
}

.single-banner img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

.banner-head {
  position: absolute;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  /* height: 100%; */
  transition: top 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.749);
}

.banner-head-text h4 {
  text-align: center;
  color: white;
  padding: 5px;
}

.single-banner:hover .banner-head {
  overflow: hidden;
  top: -100%;
  transition: top 0.3s ease-in-out;
}

/* product slider */
.product.carousel.slide {
  background-image: url(../public/assets/images/bg/productslider.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-product.carousel.slide {
  background-image: url(../public/assets/images/bg/productslider.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  height: 519px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product.carousel.slide .banner.carousel-inner {
  width: 191px;
  left: 1px;
  height: 366px;
  /* border-radius: 30px; */
  /* z-index: ; */
}

.mobile-product.carousel.slide .banner.carousel-inner {
  width: 225px;
  height: 430px;
  /* border-radius: 30px; */
  /* z-index: ; */
}

@media (max-width: 768px) {
  .product.carousel.slide .banner.carousel-inner {
    width: 181px;
  }

  .loginbtn {
    padding: 3px 10px;
    font-size: 13px;
  }
}

@media (max-width: 479px) {
  .product.carousel.slide {
    background-size: cover;
  }

  .product.carousel.slide .banner.carousel-inner {
    width: 191px;
  }
}

.product.carousel.slide .banner.carousel-inner .banner-item.carousel-item {
  height: 100%;
}

.product.carousel.slide .banner.carousel-inner .banner-item.carousel-item img {
  object-fit: contain;
  position: relative;
}

.product.carousel.slide .banner-item.carousel-item.active .banner-info {
  position: absolute;
  top: 54%;
  border: 1px solid black;
  left: 50%;
  transform: translate(-50%);
  width: 95%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.712);
}

.mobile-product.carousel.slide .banner-item.carousel-item.active .banner-info {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.banner-item.carousel-item.active .banner-info p {
  font-size: 11px;
}

.mobile-product.carousel.slide
  .banner-item.carousel-item.active
  .banner-info
  .row {
  width: 100%;
  background-color: white;
}

.banner-info p {
  color: rgb(0, 0, 0);
}

.banner-info button {
  color: rgb(31, 4, 4);
  /* border: 2px solid white; */
}

button.addtocart {
  background-color: rgb(189, 50, 40);
  color: white;
  border: none;
}

button.addtocart:hover {
  color: white;
}

/* .product.carousel.slide .banner-item.carousel-item {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.product.carousel.slide .banner-item.carousel-item.active {
  opacity: 1;
  transition: opacity 1s ease-in-out;
} */

.carousel-inner.testimonial-carousel .carousel-item .carousel-caption {
  /* background-color: white; */
  width: 70%;
  border-radius: 50px;
  padding: 50px;
  margin: 0 auto;
  background-color: white;
}

.caption img {
  object-fit: contain;
  max-height: 100px;
}

.footer-top,
.widget-footer h6,
.footer-bottom {
  background-color: black;
  color: white;
}

.sideimages-row,
.sideimages,
.sideimages .container {
  height: 100%;
}

.sideimages {
  position: absolute;
  top: 0;
}

.sideimages {
  z-index: -1;
}

.botton-cta-inner {
  position: relative;
  height: 400px;
  width: 55vw;
  /* background-color: lightgray; */
  background-image: linear-gradient(90deg, #5170ff, #ff66c4);
}

.cta-contact {
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  box-shadow: 0 0 5px 5px #5171ff65;
  background-color: white;
}

@media (max-width: 1260px) {
  .botton-cta-inner {
    height: 500px;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  transform: rotate(270deg);
}

/* .products-list {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
}

li.products-tool:hover .products-list {
  opacity: 1;
  visibility: visible;
} */

/* test */
.swiper-container.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }

  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }

  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

/* .slick-slide {
  background-color: #5170ff;
  padding: 5px;
  border: 5px solid rgb(255, 255, 255);
}
.slide-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
button.slick-arrow{
  background-color: black;
}
.slick-slide.slick-active.slick-center.slick-current{
  transform: scale(1.5);
  transition: transform 0.3s ease;
} */
/* Testslider.css */

/* .slick.container {
  width: 80%;
  margin: auto;
  position: relative;
}

.slide.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-radius: 5px;
  transition: transform 0.3s ease;
  cursor: pointer; */
/* } */

/* Add styles for the center slide */
.slide.slick-slide {
  /* transform: scale(1.5); */
  background-color: #5170ff;
  border: 5px solid rgb(218, 196, 196);
}

/* Styles for the previous and next buttons */
/* .slick-arrow.slick-prev,
.slick-arrow.slick-next {
  position: absolute;
  bottom: 20px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
} */

.single-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.special-instructions {
  /* Required styles for compatibility with older browsers */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Add dotted lines to indicate text truncation */
  text-overflow: ellipsis;
}

#specialinstructions::placeholder {
  font-size: 0.9rem;
}

.error-message {
  color: red;
}

.single-form-row {
  margin-bottom: 10px !important;
}

.btn-danger {
  color: white;
  background-color: rgb(189, 50, 40);
}

.offcanvas {
  width: 80% !important;
}

.botton-cta-inner-2 {
  position: relative;
}

.cta-back {
  height: 100%;
  position: absolute;
  width: 74%;
  background-image: linear-gradient(90deg, #5170ff, #ff66c4);
  top: 20px;
  /* margin: auto; */
  left: 20%;
  border-radius: 5px;
  z-index: -1;
}

.botton-cta-inner-2 .row {
  background-color: white;
}

.category-collapse.collapse {
  max-height: 400px;
  overflow-y: auto;
}

.product_details_container .row .col-2 {
  max-height: 500px;
  overflow-y: auto;
}

.mobilesmall {
  max-width: 100%;
  overflow-x: auto;
}

/* payment page */

form.payment {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button.payment-btn {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button.payment-btn:hover {
  filter: contrast(115%);
}

button.payment-btn:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner.payment-spin,
.spinner.payment-spin:before,
.spinner.payment-spin:after {
  border-radius: 50%;
}

.spinner.payment-spin {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner.payment-spin:before,
.spinner.payment-spin:after {
  position: absolute;
  content: "";
}

.spinner.payment-spin:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner.payment-spin:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form.payment {
    width: 80vw;
    min-width: initial;
  }
}

.coloritem {
  cursor: pointer;
}

.payment-form {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.orders-table thead tr {
  position: sticky;
  top: 0;
  z-index: 99;
}

.orders-table {
  max-height: 490px;
  overflow-y: auto;
}

.logo_payment {
  height: 20px;
  margin: 20px 0px;
}
.logo_payment_stripe {
  height: 30px;
  margin: 15px 0px;
}

.gateway_cards_int:hover {
  scale: 1.01;
}

.card_font{
  font-size: xx-large;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
